@use './app/styles/common' as c;

* {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}


body {
  margin: 0;
  position: relative;
  color: map-get(c.$white, main);
  background-color: map-get(c.$black, back);
  width: 100%;
  height: 100%;
  padding-left: 220px;
  padding-top: 60px;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

.page {
  &__wrapper {
    &_title {
      @include c.page-title;
    }
  }
}

.modal {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  background: map-get(c.$black, full);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 10;

  &__main {
    width: 420px;
    border-radius: 8px;
    background: map-get(c.$black, input);

    &_header {
      background: map-get(c.$gradient, modal);
      height: 58px;
      width: 100%;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      & .title {
        font-size: 20px;
        line-height: 26px;
        font-weight: bold;
        display: flex;
        align-items: center;

        & mat-icon {
          margin-right: 12px;
          width: 16px;
        }
      }

      & .close-icon {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        width: 16px;
        height: 16px;
      }
    }

    &_body {
      padding: 0 20px;

      & .between-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 15px 0;

        &:last-child {
          margin-bottom: 0;
        }

        &_right, &_left {
          display: flex;
          align-items: center;
        }

        & img {
          width: 24px;
          height: 24px;
          margin-left: 10px;
        }
      }
    }

    &_footer {
      padding: 20px 20px 0;
    }

    & .btn_wrapper {
      padding: 20px;
    }
  }
}

.show-link-icon {
  position: relative;
  margin-right: 15px !important;
  cursor: pointer;

  &::after {
    content: "";
    background: url('./assets/icons/link-arrow.svg');
    width: 8px;
    height: 8px;
    display: block;
    position: absolute;
    background-position: center;
    top: 0;
    right: -13px;
  }

}


.page {
  padding: 28px 68px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_title {
      font-size: 36px;
      font-weight: bold;
      line-height: normal;
      color: #FFFFFF;
    }
  }
}

.ngx-slider {
  & .ngx-slider-bar {
    background: #383941 !important;
    height: 8px !important;
  }

  & .ngx-slider-bubble {
    visibility: hidden !important;
  }

  & .ngx-slider-selection {
    background: #3246DD !important;
  }

  & .ngx-slider-pointer {
    width: 0 !important;
    height: 0 !important;

    &.ngx-slider-active {
      &::after {
        background: white !important;
      }
    }

    &::after {
      width: 14px !important;
      height: 14px !important;
      border-radius: 7px !important;
      left: -2px !important;
    }
  }

  & .ngx-slider-selection-bar {
    visibility: visible !important;
  }
}

.mat-checkbox-frame {
  border-color: #82ACFF;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: transparent;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #82ACFF;
}

.mat-table {
  background: transparent;
  box-shadow: none;

  & tr.mat-header-row {
    height: 40px;
  }

  & th.mat-header-cell {
    color: #9E9FA3;
    text-align: center;

    &:last-child {
      // text-align: right;
    }
  }

  & tr.mat-row {
    &:first-child {

      & td.mat-cell {
        &:first-child {
          border-radius: 8px 0 0 0;
        }

        &:last-child {
          border-radius: 0 8px 0 0;
        }
      }
    }

    &:last-child {

      & td.mat-cell {
        &:first-child {
          border-radius: 0 0 0 8px;
        }

        &:last-child {
          border-radius: 0 0 8px 0;
        }
      }
    }

    & td.mat-cell {
      color: white;
      text-align: center;

      & img {
        vertical-align: middle;
        margin-right: 5px;
      }

      & .green {
        color: #52F6A6;
      }

      & .grey {
        color: #FFFFFF;
        opacity: 0.5;
        margin-left: 5px;
      }

      &:last-child {
        // text-align: right;
      }
    }

    &:nth-child(odd) {
      background-color: rgba(255, 255, 255, 0.05);
    }

    &:nth-child(even) {
      background-color: rgba(255, 255, 255, 0.02);
    }
  }
}

.w3s-progress-steps-container {
  background: map-get(c.$black, border);
  color: #fff;
}

.w3s-progress-bar-container {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.w3s-progress-bar {
  background-color: map-get(c.$green, light) !important;
}

.connect-wallet__container {
  margin-right: 0 !important;
}

.mat-slider-horizontal {
  height: 8px !important;
  min-width: 100% !important;
}

.mat-slider {
  padding: 0 !important;
}

.mat-slider-horizontal .mat-slider-wrapper {
  height: 8px !important;
  top: 0 !important;
}

.mat-slider-horizontal .mat-slider-track-background,
.mat-slider-horizontal .mat-slider-track-wrapper,
.mat-slider-horizontal .mat-slider-track-fill {
  height: 8px !important;
}

.mat-slider-horizontal .mat-slider-track-wrapper {
  border-radius: 8px;
  overflow: hidden;
}

.mat-slider-track-background {
  background-color: #383941 !important;
}

.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #FFFFFF !important;
}

.mat-slider.mat-accent .mat-slider-track-fill {
  background-color: #3246DD !important;
}

.ngx-slider.animate .ngx-slider-pointer:focus-visible {
  outline: none !important;
}

@media screen and (max-width: 959.98px) {
  body {
    padding-left: 0;
  }

  .page {
    padding: 14px 34px;
  }
}

@media screen and (max-width: 600px) {
  .page {
    padding: 10px;
  }

  .modal {
    &__main {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &_header {
        background: none;
        width: 100%;
        position: initial;
      }

      &_body {
        width: 100%;
      }

      &_footer {
        width: 100%;
      }

      & .btn_wrapper {
        width: 100%;
      }
    }
  }
}
