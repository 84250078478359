%common {
    text-align: center;
    color: #FFFFFF;
}

@mixin regular-text-12 {
    @extend %common;
    font-size: 12px;
    line-height: 16px;
}

@mixin regular-text-16 {
    @extend %common;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}

@mixin page-title {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 20px;
}

@mixin page-description {
    @extend %common;
    font-size: 14px;
    line-height: 20px;
    opacity: 0.7;
}

@mixin d-flex($al, $just, $direction: row) {
    display: flex;
    align-items: $al;
    justify-content: $just;
    flex-direction: $direction;
}
