@use './colors' as c;


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  width: 100%;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  position: relative;

  &.loading, &.disabled, &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: .2;
  }

  &.loading {
    display: flex;
    &::before {
      content: '';
      background: url("../../assets/icons/loading.svg") center no-repeat;
      height: 15px;
      width: 15px;
      position: relative;
      margin-right: 6px;
      animation: 1s spin infinite;
    }
  }

  &-white {
    background: map-get(c.$white, main);
    color: map-get(c.$blue, button);

    &:hover {
      background: linear-gradient(0deg, rgba(50, 70, 221, 0.15), rgba(50, 70, 221, 0.15)), map-get(c.$white, main);
    }
  }

  &-blue {
    color: map-get(c.$white, main);
    background: map-get(c.$blue, button);

    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), map-get(c.$blue, button);
    }
  }

  &-green {
    color: map-get(c.$white, main);
    background: map-get(c.$green, main);

    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), map-get(c.$green, main);
    }
  }

  &-red {
    color: map-get(c.$white, main);
    background: map-get(c.$red, main);

    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), map-get(c.$red, main);
    }
  }

  &-black {
    color: map-get(c.$white, main);
    background: map-get(c.$black, side);

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  &-transparent {
    color: map-get(c.$white, main);
    background: transparent;
    border: 2px solid map-get(c.$white, main);
    padding: 10px 0;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}
